import React, { useEffect, useMemo, useRef, useState } from "react";
import { Button, Col, Row } from "antd";
import useTvView from "./useTvView";
import { Space } from "shared/ui";
import { useNavigate } from "react-router";
import { ReactComponent as Start } from "./start.svg";
import { ReactComponent as Stop } from "./stop.svg";
import { ProductGroup } from "PriceMonitoring/modal";
import Groups from "./Groups";
import { prepareData } from "PriceMonitoring/components/createClassifierTreeList";
import * as ClassifierApi from "../../api/Classifier";
import { Node } from "../modal";
import { record, read } from "./openGroupsTV";
const TvView = () => {
  const navigate = useNavigate();
  const { started, startTimer, stopTimer, groups, timestamp, changeOrder } =
    useTvView();
  const [productClassifier, setProductClassifier] = useState<Node[]>([]);

  const [collapse, setCollapsed] = useState<Set<number>>(new Set(read()));

  const handleCollpsed = (id: number) => {
    setCollapsed((prevSet) => {
      const newSet = new Set(prevSet);
      newSet.has(id) ? newSet.delete(id) : newSet.add(id);
      record(Array.from(newSet.values()));
      return newSet;
    });
  };
  const handleCollpsedAll = () => {
    setCollapsed((prevSet) => {
      const newSet = new Set([]);

      record([]);
      return newSet;
    });
  };
  const fetchGoods = async () => {
    setProductClassifier(await ClassifierApi.getAll());
  };
  useEffect(() => {
    fetchGoods();
  }, []);
  const [classifireList, childMap] = useMemo(() => {
    const data = prepareData(productClassifier, collapse, false);
    data.push({
      node: { id: 0, name: "Без категории" },
      indent: 0,
    });
    const data2 = prepareData(productClassifier, new Set([]), true);
    const map = new Map();
    data2.forEach((el) => {
      if (el.node.children?.length) {
        map.set(
          el.node.id,
          el.node.children.map((el) => el.id)
        );
      }
    });

    return [data, map];
  }, [productClassifier, collapse]);
  const groupsByCategory = useMemo(() => {
    const result = groups.reduce((acc, el) => {
      const category = el.classifier || { id: 0, name: "Без категории" };
      return {
        ...acc,
        [category.id]: acc[category.id]
          ? { ...acc[category.id], groups: [...acc[category.id].groups, el] }
          : {
              categoryId: category.id,
              categoryName: category.name,
              groups: [el],
            },
      };
    }, {} as Record<number, { categoryId: number; categoryName: string; groups: ProductGroup[] }>);
    return result;
  }, [groups]);

  return (
    <>
      <div>
        {!started && (
          <Button onClick={startTimer}>
            <Start style={{ margin: "-2px 4px 0 0" }} />
            Включить автообновление
          </Button>
        )}
        {started && (
          <Button onClick={stopTimer}>
            <Stop style={{ margin: "-2px 4px 0 0" }} />
            Остановить автообновление
          </Button>
        )}
        <Space size={16} inline />
        <Button type="default" onClick={() => navigate("..")}>
          К редактированию
        </Button>
        <Space size={16} inline />
        <Button type="default" onClick={() => handleCollpsedAll()}>
          Свернуть все группы
        </Button>
      </div>
      <Space size={32} axis="vertical" />
      <div>
        {classifireList.map((el) => {
          const group = groupsByCategory[el.node.id];

          return (
            <Groups
              key={el.node.id}
              groups={group?.groups || []}
              title={el.node.name}
              changeOrder={changeOrder}
              timestamp={timestamp}
              categoryId={el.node.id}
              collapsed={!collapse.has(el.node.id)}
              onCollapsed={handleCollpsed}
              indent={el.indent}
            />
          );
        })}
      </div>
    </>
  );
};
export default TvView;
