import React, { useEffect, useState } from "react";
import { Col, Container, Row, Button, Tabs, Tab } from "react-bootstrap";
import { Form } from "react-bootstrap";
import { updateIntegration } from "../../api/MarketplaceAuth";
import { getAuthStatus, AuthStatus } from "../../api/MarketplaceAuth";

const AuthWb = (props: {
  title: string;
  marketpalce: string;
  data: AuthStatus;
}) => {
  const [sellerIdNew, setSellerIdNew] = useState("");
  const [apiToken, setApiToken] = useState("");
  const [cookie, setCookie] = useState("");
  const [fbsWarehouseId, setFbsWarehouseId] = useState("");

  const updateData = async () => {
    await updateIntegration({
      markeplace: props.marketpalce,
      cookie: cookie || undefined,
      sellerId: sellerIdNew || undefined,
      fbsWarehouseId: fbsWarehouseId || undefined,
      apiToken: apiToken || undefined,
    });
  };

  return (
    <Container>
      <Row>
        <Col xs={12}>
          <h4 style={{ margin: "16px 0 32px" }}>{props.title}</h4>
          <Row>
            <Col>
              <Form.Label>
                Seller Id
                <br />
                (В шапке сайта)
              </Form.Label>
            </Col>
            <Col xs={8} md={8} lg={8}>
              <Form.Control
                placeholder={props.data.sellerId || "Seller Id"}
                value={sellerIdNew}
                onChange={(e) => {
                  setSellerIdNew(e.target.value);
                }}
              />
            </Col>
          </Row>
          <Row style={{ marginTop: "16px" }}>
            <Col>
              <Form.Label>
                Api token
                <br />
                (в настройках)
              </Form.Label>
            </Col>
            <Col xs={8}>
              <Form.Control
                placeholder={props.data.apiToken || "ApiToken"}
                value={apiToken}
                onChange={(e) => {
                  setApiToken(e.target.value);
                }}
              />
            </Col>
          </Row>
          <Row style={{ marginTop: "16px" }}>
            <Col>
              <Form.Label>FBS warehouse id</Form.Label>
            </Col>
            <Col xs={8}>
              <Form.Control
                placeholder={props.data.fbsWarehouseId || "FBS warehouse id"}
                value={fbsWarehouseId}
                onChange={(e) => {
                  setFbsWarehouseId(e.target.value);
                }}
              />
            </Col>
          </Row>
          <Row style={{ marginTop: "16px" }}>
            <Col>
              <Form.Label>Cookie</Form.Label>
            </Col>
            <Col xs={8}>
              <Form.Control
                as={"textarea"}
                placeholder={props.data.cookie || "cookie"}
                value={cookie}
                onChange={(e) => {
                  setCookie(e.target.value);
                }}
              />
            </Col>
          </Row>
          <Row style={{ marginTop: "16px" }}>
            <Col>
              <Button
                onClick={(e) => {
                  e.preventDefault();
                  updateData();
                }}
              >
                Сохранить
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default AuthWb;
