import React from "react";
import Header from "../Header";
import styled from "styled-components";
const Container = styled.div`
  min-width: 1024px;
  margin: auto;
`;
const Wrap = styled.div`
  -webkit-box-align: stretch;
  align-items: stretch;
  /* min-height: 100vh; */
  min-height: calc(100vh - 70px);
  height: 100%;
  min-width: 1024px;
  margin: auto;
  padding: 40px 28px;
`;
const LayoutFullWidth = (props: React.PropsWithChildren) => {
  return (
    <Container>
      <Header></Header>
      <Wrap>{props.children}</Wrap>
      <div style={{ paddingTop: 32 }} />
    </Container>
  );
};

export default LayoutFullWidth;
