import React, { useEffect, useState } from "react";
import AuthOzon from "./AuthOzon";
import Auth from "./Auth";
import { getAuthStatus, AuthStatus } from "../../api/MarketplaceAuth";
import { Col, Container, Row, Button, Tabs, Tab } from "react-bootstrap";
import { Form } from "react-bootstrap";

import { Select } from "antd";

const avalibelOptions = ["wildberries", "ozon", "ozon_fresh"].map((el) => ({
  value: el,
  labal: el,
}));
const AuthMarketplace = () => {
  const [state, setState] = useState<AuthStatus[]>([]);
  const [select, setSelect] = useState<{
    value: string;
    labal: string;
  } | null>(null);
  const options = avalibelOptions.filter(
    (el) => !state.some((s) => s.markerplace === el.value)
  );
  useEffect(() => {
    getAuthStatus().then((res) => {
      setState(res);
    });
  }, []);

  const addIntegration = () => {
    if (!select) return;
    console.log(select);
    setState([
      ...state,
      {
        markerplace: select.value,
        sellerId: "",
        apiToken: "",
        fbsWarehouseId: "",
        publicToken: "",
        cookie: "",
      },
    ]);
    setSelect(null);
  };

  return (
    <>
      {options.length ? (
        <>
          Добавить интерацию
          <Container>
            <Row>
              <Col xs={12}>
                <h4 style={{ margin: "16px 0 32px" }}>Добавить интерацию</h4>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <Row>
                  <Col xs={6} md={6} lg={6}>
                    <Select
                      placeholder="Выбрать маркетплейс"
                      options={options}
                      value={select}
                      onSelect={(e, v) => {
                        setSelect(v);
                      }}
                    />
                  </Col>
                  <Col xs={6} md={6} lg={6}>
                    <Button onClick={addIntegration}>Добавить</Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </>
      ) : null}
      {state.map((el) => (
        <Auth
          key={el.markerplace}
          title={el.markerplace}
          marketpalce={el.markerplace}
          data={el}
        />
      ))}
    </>
  );
};

export default AuthMarketplace;
