import React, { useEffect, useState, useMemo, FormEvent } from "react";

import { Table, Button, Row, Col, Card, List } from "antd";

import { addJob, clearJobs } from "api/Reviews";
import {
  productsOzonLoadProducts,
  productsWbLoadProducts,
  reviewsOzonJobApprove,
  reviewsOzonJobGenerate,
  reviewsOzonJobLoadNew,
  reviewsOzonJobLoadOld,
  reviewsOzonJobReset,
  reviewsOzonJobSend,
  reviewsWbJobApprove,
  reviewsWbJobGenerate,
  reviewsWbJobLoadNew,
  reviewsWbJobLoadOld,
  reviewsWbJobReset,
  reviewsWbJobSend,
} from "../queue";
import { setOzonCookieToBrowser } from "api/MarketplaceAuth";
import { updateOzonCookieFromBrowser } from "api/MarketplaceAuth";
import { markeplaceOptions } from "Answers/components/Filters/filterOptions";

const DelayJobs = () => {
  const handle = async (data: { name: string; data: any }) => {
    addJob(data);
  };
  const handleClear = async () => {
    clearJobs();
  };
  const updateBrowserCookie = () => {
    setOzonCookieToBrowser();
  };

  const updateCookieFromBrowser = () => {
    updateOzonCookieFromBrowser();
  };
  return (
    <>
      <Card title="Общее">
        <Row>
          <Col xs={6}>
            <Button onClick={() => updateCookieFromBrowser()}>
              подтянуть куки озона из браузера
            </Button>{" "}
            <Button onClick={() => updateBrowserCookie()}>
              обновить куки озона в браузера из кода
            </Button>{" "}
          </Col>
          <Col xs={6}>
            <Button
              onClick={() => handle({ name: productsWbLoadProducts, data: {} })}
            >
              Подтянуть список товаров с WB
            </Button>{" "}
            <Button
              onClick={() =>
                handle({
                  name: productsOzonLoadProducts,
                  data: { markeplace: "ozon" },
                })
              }
            >
              Подтянуть список товаров с OZON
            </Button>{" "}
          </Col>
        </Row>
      </Card>

      <Card title="Отзывы">
        <Row>
          <Col xs={6}>
            <Button onClick={() => handleClear()}>
              Отчистить очередь отзывов
            </Button>{" "}
          </Col>
        </Row>
        <Row>
          <Col xs={6}>
            <Button
              onClick={() => handle({ name: reviewsWbJobLoadNew, data: {} })}
            >
              Выгрузить новые отзывы ВБ
            </Button>
            <Button
              onClick={() => handle({ name: reviewsWbJobLoadOld, data: {} })}
            >
              Выгрузить старые отзывы ВБ
            </Button>
            <Button
              onClick={() => handle({ name: reviewsWbJobReset, data: {} })}
            >
              Сбросить состояние прогресса
            </Button>
            <Button
              onClick={() => handle({ name: reviewsWbJobGenerate, data: {} })}
            >
              сгенерировать
            </Button>
            <Button
              onClick={() => handle({ name: reviewsWbJobApprove, data: {} })}
            >
              апрувнуть 500 отзывов
            </Button>
            <Button
              onClick={() => handle({ name: reviewsWbJobSend, data: {} })}
            >
              Отправить ответы на отзывы
            </Button>
          </Col>

          <Col xs={6}>
            <Button
              onClick={() =>
                handle({
                  name: reviewsOzonJobLoadNew,
                  data: { marketplace: "ozon" },
                })
              }
            >
              Выгрузить новые отзывы Ozon
            </Button>
            <Button
              onClick={() =>
                handle({
                  name: reviewsOzonJobLoadOld,
                  data: { marketplace: "ozon" },
                })
              }
            >
              Выгрузить обработанные отзывы Ozon
            </Button>
            <Button
              onClick={() =>
                handle({
                  name: reviewsOzonJobReset,
                  data: { marketplace: "ozon" },
                })
              }
            >
              Сбросить состояние прогресса
            </Button>
            <Button
              onClick={() =>
                handle({
                  name: reviewsOzonJobGenerate,
                  data: { marketplace: "ozon" },
                })
              }
            >
              сгенерировать
            </Button>
            <Button
              onClick={() =>
                handle({
                  name: reviewsOzonJobApprove,
                  data: { marketplace: "ozon" },
                })
              }
            >
              апрувнуть 500 отзывов
            </Button>
            <Button
              onClick={() =>
                handle({
                  name: reviewsOzonJobSend,
                  data: { marketplace: "ozon" },
                })
              }
            >
              Отправить ответы на отзывы
            </Button>
          </Col>
        </Row>
      </Card>
    </>
  );
};

export default DelayJobs;
