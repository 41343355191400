import React, { useEffect, useState, useMemo, FormEvent } from "react";
import { Col, Container, Row, Form, Button } from "react-bootstrap";
import * as ProductCategoriesApi from "../api/ProductCategories";
import { ProductCategoryProps } from "../api/ProductCategories";
import colors from "../shared/colors";
import { Tabs, Tab } from "../shared/SwitchTabs";
import {
  Routes,
  Route,
  Router,
  Navigate,
  useRoutes,
  useParams,
  useNavigate,
} from "react-router";
import styled from "styled-components/macro";

import AnswerTemplates from "./AnswerTemplates";
import ProductCategoies from "./ProductCategoies";
import AuthMarketplace from "./AuthMarketplace";
import Goods from "./Goods";
import Logs from "./Logs";
import FeatureFlags from "./FeatureFlags";
import Classifier from "./Classifier/Classifier";
import { Space } from "shared/ui";
import DelayJobs from "./DelayJobs";

const Wrap = styled.div`
  overflow: auto;
  max-width: 100%;
`;

const Settings = () => {
  const params = useParams();
  const path = Object.values(params)[0];
  const navigate = useNavigate();
  return (
    <Container>
      <Row>
        <Wrap>
          <Space size={32} axis="vertical" />
          <Tabs>
            <Tab
              key={"answer_template"}
              onSelect={() => {
                navigate("answer_template");
              }}
              isActive={path === "answer_template"}
            >
              Шаблоны ответов
            </Tab>
            <Tab
              key={"product_categories"}
              onSelect={() => {
                navigate("product_categories");
              }}
              isActive={path === "product_categories"}
            >
              Категории товаров
            </Tab>
            <Tab
              key={"goods"}
              onSelect={() => {
                navigate("goods");
              }}
              isActive={path === "goods"}
            >
              Товары
            </Tab>
            <Tab
              key={"auth_marketplace"}
              onSelect={() => {
                navigate("auth_marketplace");
              }}
              isActive={path === "auth_marketplace"}
            >
              Авторизация в МП
            </Tab>
            <Tab
              key={"logs"}
              onSelect={() => {
                navigate("logs");
              }}
              isActive={path === "logs"}
            >
              ЛОГИ
            </Tab>
            <Tab
              key={"features"}
              onSelect={() => {
                navigate("features");
              }}
              isActive={path === "features"}
            >
              Фича-флаги
            </Tab>
            <Tab
              key={"classifier"}
              onSelect={() => {
                navigate("classifier");
              }}
              isActive={path === "classifier"}
            >
              Классификатор
            </Tab>
            <Tab
              key={"delay_jobs"}
              onSelect={() => {
                navigate("delay_jobs");
              }}
              isActive={path === "delay_jobs"}
            >
              Запуск задач
            </Tab>
          </Tabs>
          <Space size={32} axis="vertical" />
        </Wrap>
      </Row>
      <Routes>
        <Route path="goods" element={<Goods />}></Route>
        <Route path="answer_template" element={<AnswerTemplates />}></Route>
        <Route path="product_categories" element={<ProductCategoies />}></Route>
        <Route path="auth_marketplace" element={<AuthMarketplace />}></Route>
        <Route path="logs" element={<Logs />}></Route>
        <Route path="features" element={<FeatureFlags />}></Route>
        <Route path="classifier" element={<Classifier />}></Route>
        <Route path="delay_jobs" element={<DelayJobs />}></Route>
        <Route
          index
          element={<Navigate to="answer_template" replace />}
        ></Route>
        <Route
          path="*"
          element={<Navigate to="answer_template" replace />}
        ></Route>
      </Routes>
      <Row>
        <Col></Col>
      </Row>
    </Container>
  );
};

export default Settings;
