import React from "react";
import { Container, Row } from "react-bootstrap";
import PriceMonitoringTv from "../PriceMonitoring/tvView";
import LayoutFullWidth from "../shared/Layout/LayoutFullWidth";

const PriceMonitorinTvViewPage = () => {
  return (
    <LayoutFullWidth>
      <PriceMonitoringTv />
    </LayoutFullWidth>
  );
};

export default PriceMonitorinTvViewPage;
