import { Product } from "./modal";

export const getTimeBetweenDates = (startDate: Date, endDate: Date) => {
  const seconds = Math.floor((endDate.getTime() - startDate.getTime()) / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  return {
    seconds: seconds - minutes * 60,
    minutes: minutes - hours * 60,
    hours: hours - days * 24,
    days,
  };
};

export const getPassedTime = (startDate: Date, endDate: Date) => {
  const value = getTimeBetweenDates(startDate, endDate);

  return value.days
    ? `${value.days}d`
    : value.hours > 1
    ? `${value.hours}h`
    : `${(value.hours && `${value.hours}h `) || ""}${
        ((value.minutes || (value.days===0 && value.hours===0) ) && `${value.minutes}m`) || ""
      }`;
};
const round = (price:number)=> Math.round(price * 10)/10
export const sortProducts = (data: Product[]) => {
  const items = [...data];
  items.sort((a, b) => {
   
    const minA = Math.min(
      ...(a.links?.map((el) => round((el.last_price_log?.discount_price  || Infinity) /(a.quantity ||1))) || [])
    );
    const minb = Math.min(
      ...(b.links?.map((el) => round((el.last_price_log?.discount_price || Infinity) /(b.quantity ||1)), 1)  || [])
    );
    return minA < minb ? -1 : minA > minb ? 1 : 0;
  });
  return items
};
