import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import React, { useEffect, useState, useMemo, FormEvent } from "react";
import { Col, Row, Form, Button, Tabs, Tab } from "react-bootstrap";
import { ProductCategoryProps } from "../api/ProductCategories";
import { ItemProductCategoryProps } from "./useProductCategoies";
import { Space } from "shared/ui";
import { Select, Spin } from "antd";
import { debounce } from "@mui/material";
import { Label } from "@amcharts/amcharts5";

const defaultValues: ItemProductCategoryProps = {
  name: "",
  genitive: "",
  accusative: "",
  products: [],
  goods: [],
};
export const ProductCategoiesItem = ({
  id,
  data = defaultValues,
  ...props
}: {
  id?: number;
  data?: ItemProductCategoryProps;
  onCreateCategory?: (props: ItemProductCategoryProps) => void;
  onUpdateCategory?: (props: ProductCategoryProps) => void;
  onDeleteCategory?: (id: number) => void;
  goodsOptions: { article: string; name: string }[];
}) => {
  const [edit, setEdit] = useState(!id);
  const [marketplace, setMarketplace] = useState("goods");
  const [name, setName] = useState(data.name);
  const [genitive, setGenitive] = useState(data.genitive);
  const [accusative, setAccusative] = useState(data.accusative);
  const [products, setProducts] = useState(data.products || []);
  const [productsString, setProductsString] = useState("");
  const [searchValue, setSearchValue] = useState("");

  const [goods, setGoods] = useState<{ article: string; name: string }[]>(
    data.goods || []
  );
  const [goodsOptions, setGoodsOptions] = useState(
    props.goodsOptions.map((el) => ({
      value: el.article,
      label: `${el.article}; ${el.name}`,
    }))
  );

  const search = (value: string) => {
    console.log({ value });
    setSearchValue(value);
    setGoodsOptions(
      props.goodsOptions
        .filter((el) =>
          `${el.article} ${el.name}`.toLowerCase().includes(value.toLowerCase())
        )
        .map((el) => ({
          value: el.article,
          label: `${el.article}; ${el.name}`,
        }))
    );
  };
  const validForm = useMemo(() => {
    return !!name && !!genitive && !!accusative;
  }, [name, genitive, accusative]);
  const handleDelete = async (e: FormEvent<HTMLButtonElement>) => {
    e.preventDefault();
    const result = window.confirm("Действительно хотите удалить?");
    if (!result) return;
    props.onDeleteCategory!(id!);
  };
  const handleSubmit = async (e: FormEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (!validForm) return;
    if (id) {
      props.onUpdateCategory!({
        id,
        name,
        genitive,
        accusative,
        products,
        goods,
      });
    } else {
      props.onCreateCategory!({
        name,
        genitive,
        accusative,
        products,
        goods,
      });
    }
  };

  const reset = () => {
    setName(data.name);
    setGenitive(data.genitive);
    setAccusative(data.accusative);
    setProducts(data.products);
    setGoods(data.goods);
  };

  useEffect(() => {
    reset();
  }, [data]);

  useEffect(() => {
    setProductsString(
      products
        .filter((el) => el.marketplace === marketplace)
        .map((el) => el.id)
        .join("\n")
    );
  }, [marketplace, products]);
  if (!edit) {
    return (
      <Row style={{ margin: "4px 0" }}>
        <Col md={3} xs={6}>
          {name}
        </Col>
        <Col md={3} xs={6}>
          <Button
            onClick={(e) => {
              e.preventDefault();
              setEdit(true);
            }}
          >
            редактировать
          </Button>
        </Col>
      </Row>
    );
  }
  return (
    <Form
      style={{
        margin: "16px -16px",
        background: "#ececec",
        padding: 16,
        borderRadius: 8,
      }}
    >
      <Row>
        <Col md={6} xs={12}>
          <Form.Group style={{ marginBottom: "8px" }}>
            <Form.Label>Название</Form.Label>
            <Form.Control
              placeholder="Название категории"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </Form.Group>

          <Form.Group style={{ marginBottom: "8px" }}>
            <Form.Label>Родительный падеж</Form.Label>
            <Form.Control
              placeholder="Категория в родительном падеже"
              value={genitive}
              onChange={(e) => setGenitive(e.target.value)}
            />
          </Form.Group>

          <Form.Group style={{ marginBottom: "8px" }}>
            <Form.Label>Винительный падеж</Form.Label>
            <Form.Control
              placeholder="Категория в винительном падеже"
              value={accusative}
              onChange={(e) => setAccusative(e.target.value)}
            />
          </Form.Group>
        </Col>
        <Col md={6} xs={12}>
          <Form.Group>
            <Tabs
              activeKey={marketplace}
              onSelect={(k) => setMarketplace(k || "goods")}
              style={{ border: "none", marginLeft: 16, marginTop: -8 }}
            >
              <Tab eventKey="goods" title="Товары"></Tab>
            </Tabs>

            <>
              <Select
                labelInValue
                filterOption={false}
                onSearch={search}
                notFoundContent={null}
                mode="multiple"
                value={goods.map((el) => ({
                  value: el.article,
                  label: el.name,
                }))}
                searchValue={searchValue}
                onBlur={() => setSearchValue("")}
                placeholder="Select users"
                onChange={(e: any, value) => {
                  console.log(value);
                  setGoods(
                    (value as any).map((el: any) => ({
                      article: el.value,
                      name: el.label,
                    }))
                  );
                }}
                options={goodsOptions}
                style={{ width: "100%", height: 200 }}
              />
            </>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Button
            style={{ margin: "16px 16px 16px 0" }}
            variant="primary"
            type="submit"
            onClick={handleSubmit}
            disabled={!validForm}
          >
            Сохранить
          </Button>
          {!!id && (
            <Button
              style={{ margin: "16px 16px 16px 0" }}
              variant="secondary"
              type="submit"
              onClick={(e) => {
                e.preventDefault();
                reset();
              }}
            >
              Cбросить изменения
            </Button>
          )}
          {!!id && (
            <Button
              style={{ margin: "16px 16px 16px 0" }}
              variant="secondary"
              type="submit"
              onClick={handleDelete}
            >
              Удалить
            </Button>
          )}
          {!!id && (
            <Button
              style={{ margin: "16px 16px 16px 0" }}
              variant="secondary"
              type="submit"
              onClick={(e) => {
                e.preventDefault();
                setEdit(false);
              }}
            >
              Скрыть форму
            </Button>
          )}
        </Col>
      </Row>
    </Form>
  );
};
