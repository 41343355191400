import fetchApiWithAuth from "./fetch";
export type AuthStatus = {
  markerplace: string;
  sellerId: string;
  apiToken: string;
  fbsWarehouseId: string;
  publicToken: string;
  cookie: string;
};

export type UpdateDto = {
  markeplace: string;
  cookie?: string;
  sellerId?: string;
  apiToken?: string;
  fbsWarehouseId?: string;
};

export const getAuthStatus = async () => {
  const result = await fetchApiWithAuth("/api/markerplace-auth/status");
  const data = (await result.json()) as AuthStatus[];
  return data;
};
export const setOzonCookieToBrowser = async () => {
  const result = await fetchApiWithAuth(
    "/api/markerplace-auth/set_browser_ozon_cookie",
    { method: "POST", body: "{}" }
  );
  const data = await result.json();
  return data;
};

export const updateOzonCookieFromBrowser = async () => {
  const result = await fetchApiWithAuth(
    "/api/markerplace-auth/update_ozon_cookie_from_browser",
    { method: "POST", body: "{}" }
  );
  const data = await result.json();
  return data;
};

export const updateIntegration = async (props: UpdateDto) => {
  const result = await fetchApiWithAuth(
    "/api/markerplace-auth/update_integration",
    {
      method: "POST",
      body: JSON.stringify(props),
    }
  );
  const data = await result.json();
  return data;
};
