export const reviews = "reviews";
export const reviewsWbJobLoadNew = "reviews:wb_load_new";
export const reviewsWbJobLoadOld = "reviews:wb_load_old";
export const reviewsWbJobSend = "reviews:wb_send";
export const reviewsWbJobApprove = "reviews:wb_approve";
export const reviewsWbJobReset = "reviews:wb_reset";
export const reviewsWbJobGenerate = "reviews:wb_generate";
export const reviewsOzonJobLoadNew = "reviews:ozon_load_new";
export const reviewsOzonJobLoadOld = "reviews:ozon_load_old";
export const reviewsOzonJobSend = "reviews:ozon_send";
export const reviewsOzonJobApprove = "reviews:ozon_approve";
export const reviewsOzonJobReset = "reviews:ozon_reset";
export const reviewsOzonJobGenerate = "reviews:ozon_generate";

export const products = "products";
export const productsOzonLoadProducts = "products:ozon_load_products";
export const productsWbLoadProducts = "products:wb_load_products";
